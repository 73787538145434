import React, { ReactNode } from "react";

interface IconButtonProps {
  onClick?: () => void;
  className?: string;
  ariaLabel?: string;
  children: ReactNode;
}

const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  className,
  children,
  ariaLabel,
}) => {
  return (
    <button
      className={`p-2 text-primary bg-transparent hover:bg-slate-500 hover:bg-opacity-30 focus:bg-slate-500 focus:bg-opacity-30 ${
        className || ""
      }`}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};

export default IconButton;
