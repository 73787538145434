import React from "react";
import PortfolioPage from "./Portfolio";
function App() {
  return (
    <>
      <PortfolioPage />
    </>
  );
}

export default App;
