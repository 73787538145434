import { Button } from "../../components/ui/button";

import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Settings } from "lucide-react";
import { PriceChart } from "./PriceChart";
import { OrdersTable } from "./OrdersTable";
import { InitialMarginDetails } from "./MarginDetails";
import { PortfolioOverview } from "./Overview";

function PortfolioModule() {
  const tabs = [
    { id: "open", name: "Open" },
    { id: "orders", name: "Orders" },
    { id: "settled", name: "Settled" },
    { id: "trade_histroy", name: "Trade Histroy" },
    { id: "order_history", name: "Order History" },
    { id: "funding_history", name: "Funding History" },
  ];

  return (
    <div className="max-w-5xl mx-auto px-4 pt-20 lg:pt-36 bg-transparent">
      <div className="flex flex-row flex-wrap justify-between my-5">
        <h1 className="flex-1 text-2xl text-white sm:text-3xl">
          AmateurMosquito’s Portfolio
        </h1>

        <Button
          className="font-gridnik tracking-widest uppercase min-w-28 text-muted bg-transparent hover:bg-white hover:bg-opacity-5 hover:text-muted focus:bg-white focus:bg-opacity-5 focus:text-muted"
          variant={"ghost"}
        >
          <Settings className="mr-2 h-4 w-4" /> Settings
        </Button>
      </div>

      <div className="block mb-1 w-full text-white lg:flex lg:mb-0">
        <div className="rounded-lg px-8 py-8 bg-foreground me-1 mb-1">
          <PortfolioOverview />
        </div>

        <div className="grow-[2]  flex flex-col lg:mb-1">
          <div className="flex-1 rounded-lg px-8 py-4 bg-foreground mb-1 ">
            <PriceChart />
          </div>
          <div className="flex justify-between rounded-lg px-8 py-5 bg-foreground flex-wrap lg:flex-nowrap">
            <InitialMarginDetails />
          </div>
        </div>
      </div>

      <div className="rounded-lg px-8 py-4 bg-foreground">
        <Tabs defaultValue={tabs[4].id}>
          <TabsList className="font-gridnik tracking-widest overflow-x-auto bg-transparent rounded-none w-full p-0 border-b-2 border-b-background justify-between">
            {tabs.map((tab, index) => (
              <TabsTrigger
                key={index}
                className="uppercase text-xs justify-start bg-transparent rounded-none ml-0 pl-0 data-[state=active]:border-b-primary data-[state=active]:text-primary data-[state=active]:border-b-2  data-[state=active]:bg-transparent"
                value={tab.id}
              >
                {tab.name}
              </TabsTrigger>
            ))}
          </TabsList>

          {tabs.map((tab, index) => (
            <TabsContent key={index} value={tab.id}>
              <OrdersTable />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default PortfolioModule;
