import * as React from "react";

export function UserBalance() {
  return (
    <div className="flex rounded-md items-center mx-2 my-1">
      <div className="relative content-center text-center w-5 h-5 me-2 rounded-full bg-secondary text-background">
        <span className="sr-only">Current balance</span>$
      </div>
      <div className="text-white">1,410.95</div>
    </div>
  );
}
