import { Navbar } from "../modules/common/navbar";

import React from "react";
import PortfolioModule from "../modules/portfolio";
import { Footer } from "../modules/common/footer";
import BackgroundSpinner from "../components/BackgroundSpinner";

function PortfolioPage() {
  return (
    <>
      <Navbar />

      <div className="overflow-x-hidden">
        <div className="relative z-10">
          <BackgroundSpinner
            sizeClass="h-[500px] w-[500px]"
            positionClass="top-[-300px] left-[-100px] lg:left-[183px] lg:top-[-260px]"
          />

          <BackgroundSpinner
            sizeClass="h-[1000px] w-[1000px]"
            positionClass="top-[100px] left-[100px] lg:left-[933px] lg:top-[60px]"
          />

          <BackgroundSpinner
            sizeClass="h-[300px] w-[300px]"
            positionClass="bottom-[-50px] left-[-50px] lg:left-[148px] lg:bottom-[-30px]"
          />

          <PortfolioModule />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PortfolioPage;
