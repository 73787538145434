import { Button } from "../../components/ui/button";

import React, { useState } from "react";
import { Area, AreaChart, Tooltip, ResponsiveContainer } from "recharts";
import CustomIcons from "../../components/CustomIcons";

export function PriceChart() {
  const [selectedRange, setSelectedRange] = useState("24 H");
  const [selectedMode, setSelectedMode] = useState("simple");

  const ranges = ["1 H", "24 H", "1 W", "1 M", "1 Y"];
  const modes = ["advanced", "simple"];

  const data = [
    {
      date: "Apr 1st 7:30PM",
      amt: 2400,
    },
    {
      date: "Apr 2nd 7:30PM",
      amt: 2210,
    },
    {
      date: "Apr 3rd 7:30PM",
      amt: 2590,
    },
    {
      date: "Apr 4th 7:30PM",
      amt: 3000,
    },
    {
      date: "Apr 5th 7:30PM",
      amt: 4081,
    },
    {
      date: "Apr 6th 7:30PM",
      amt: 4500,
    },
    {
      date: "Apr 7th 7:30PM",
      amt: 3100,
    },
  ];

  const CustomTooltip: React.FC<{
    active?: boolean;
    payload?: any;
    label?: string;
  }> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col items-center">
          <p className="text-base text-secondary">${payload[0].payload.amt}</p>
          <p className="text-xxs text-white">{payload[0].payload.date}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col grow h-full">
      <div className={"flex-1"}>
        <ResponsiveContainer width="100%" minHeight={150}>
          <AreaChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Tooltip
              cursor={false}
              offset={-100}
              content={<CustomTooltip />}
              cursorStyle={{
                borderColor: "transparent",
              }}
              contentStyle={{
                borderColor: "transparent",
                backgroundColor: "transparent",
              }}
            />

            <Area
              type="linear"
              dataKey="amt"
              stroke={"hsl(var(--secondary))"}
              fill={`hsl(var(--secondary))`}
              style={{
                fillOpacity: 0.15,
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="flex justify-between flex-wrap lg:flex-nowrap">
        <div className="mt-2.5 flex">
          <Button
            variant="ghost"
            className="pl-0 h-9 hover:bg-transparent"
            size="sm"
          >
            <CustomIcons.CandleStick
              alt="candlesticks"
              height={14}
              width={14}
            />
          </Button>
          {ranges.map((range, index) => {
            return (
              <Button
                key={index}
                size={"sm"}
                disabled={selectedRange === range}
                className={`${
                  selectedRange === range
                    ? "text-primary"
                    : "text-white opacity-60"
                } pl-0 font-gridnik tracking-tighter focus:text-primary hover:bg-transparent hover:text-white  text-xs disabled:opacity-100`}
                onClick={() => setSelectedRange(range)}
                variant="ghost"
              >
                {range}
              </Button>
            );
          })}
        </div>

        <div className="mt-2.5 flex">
          {modes.map((mode) => {
            return (
              <Button
                key={mode}
                size={"sm"}
                disabled={selectedMode === mode}
                className={`pr-2 pl-0 h-full uppercase font-gridnik tracking-widest hover:bg-transparent focus:text-primary hover:text-white  text-xs disabled:opacity-100 ${
                  selectedMode === mode
                    ? "text-primary"
                    : "text-white opacity-60"
                } `}
                onClick={() => setSelectedMode(mode)}
                variant="ghost"
              >
                {mode}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
