import React from "react";

export function InitialMarginDetails() {
  const data = [
    { label: "INITIAL MARGIN", amount: "$252,612.99" },
    { label: "INITIAL MARGIN", amount: "$252,612.99" },
    { label: "INITIAL MARGIN", amount: "$252,612.99" },
    { label: "INITIAL MARGIN", amount: "$252,612.99" },
  ];

  return (
    <>
      {data.map((margin, index) => (
        <div key={index}>
          <p className="font-gridnik tracking-widest text-xxs opacity-50">
            {margin.label}
          </p>
          <h1 className="text-2xl text-white">{margin.amount}</h1>
        </div>
      ))}
    </>
  );
}
