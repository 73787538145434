import * as React from "react";

export function UserWallet() {
  return (
    <div
      className={`flex rounded-md justify-center items-center px-2 py-1 hover:cursor-pointer hover:bg-slate-800`}
    >
      <span className="sr-only">Select crypto</span>
      <div className="w-5 h-5 content-center me-1 rounded-full flex items-center justify-center bg-[#5456DC]">
        <img
          className="h-5 w-5 rounded-full"
          src="/assets/images/wallet-profile-picture.png"
          alt=""
        />
      </div>
      <div className="text-white me-1">0x15v...40db2</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2.5"
        stroke="white"
        className="w-3 h-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m19.5 8.25-7.5 7.5-7.5-7.5"
        />
      </svg>
    </div>
  );
}
