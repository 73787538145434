import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import CustomIcons from "../../../components/CustomIcons";

export function CryptoSelectBox({ customClasses = "bg-background" }) {
  function CustomSelectItem({
    children,
    value,
  }: {
    children: JSX.Element;
    value: string;
  }): JSX.Element {
    return (
      <SelectItem
        className="hover:text-white focus:text-white focus:bg-gray-800 focus:cursor-pointer [data-highlighted]:bg-slate-50"
        value={value}
      >
        {children}
      </SelectItem>
    );
  }

  return (
    <Select defaultValue="eth">
      <SelectTrigger
        className={`${customClasses} text-white justify-start border-0 hover:border-0 focus:border-0 focus:ring-0 focus:ring-offset-0`}
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent className={`${customClasses} text-white`}>
        <SelectGroup className="text-white">
          <CustomSelectItem value="eth">
            <CustomIcons.Eth height={20} width={20} />
          </CustomSelectItem>
          <CustomSelectItem value="bth">
            <CustomIcons.Btc height={20} width={20} />
          </CustomSelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
