import { Button } from "../../components/ui/button";
import {
  Tooltip as ComponentTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import React from "react";
import { ChevronUp, CircleHelp } from "lucide-react";

export function PortfolioOverview() {
  const statHeader = "font-gridnik tracking-widest text-xxs opacity-50";
  const statValue = "text-4xl mt-2 mb-5";

  const data = {
    netWorth: "$57,420.34",
    fourteenDayVolume: "$2,432.94",
    healthIndex: "1.32",
  };

  return (
    <>
      <div className="flex flex-row">
        <p className={`${statHeader} text-white`}>NET WORTH</p>
        <p className="flex flex-row bg-secondary text-background rounded-sm px-3 ml-3 items-center text-[7px]">
          <ChevronUp className="mr-1.5" size={9} /> {data.netWorth}
        </p>
      </div>
      <h1 className={`${statValue} text-primary`}>{data.netWorth}</h1>
      <p className={statHeader}>14 DAY VOLUME</p>
      <h1 className={`${statValue} text-white`}>{data.fourteenDayVolume}</h1>

      <div className="flex flex-row items-center">
        <p className={statHeader}>HEALTH</p>
        <TooltipProvider>
          <ComponentTooltip>
            <TooltipTrigger>
              <CircleHelp className="opacity-50 ml-2 mr-2" size={9} />
            </TooltipTrigger>
            <TooltipContent>
              <p>Help</p>
            </TooltipContent>
          </ComponentTooltip>
        </TooltipProvider>
      </div>
      <h1 className={`${statValue} text-accent`}>{data.healthIndex}</h1>

      <p className={`${statHeader} mb-1.5`}>ADD FUNDS TO SYNQUOTE</p>
      <Button className="text-background w-full">Deposit</Button>
    </>
  );
}
