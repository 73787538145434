import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

import React from "react";

export function OrdersTable() {
  const orderTemplate = {
    id: "0",
    time: "2024-03-12:24:58",
    instrument: "BTC PERPETUAL",
    type: "Limit",
    limit_price: "$69627.00",
    size: "Short 70823BTC",
    filled_size: "—",
    time_in_price: "Good Til Canceled",
    action: "Opened",
    order_id: "15874986346563",
  };

  const orders = Array(10).fill(orderTemplate);

  return (
    <Table className="bg-foreground border-0">
      <TableHeader className="uppercase font-gridnik tracking-widest text-[9px] text-nowrap">
        <TableRow className="hover:bg-transparent border-b border-foreground">
          <TableHead>Time</TableHead>
          <TableHead>Instrument</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>Limit Price</TableHead>
          <TableHead>Size</TableHead>
          <TableHead>Filled Size</TableHead>
          <TableHead>Time In Price</TableHead>
          <TableHead>Action</TableHead>
          <TableHead>Order ID</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="text-white text-[10px]">
        {orders.map((order, index) => (
          <TableRow
            className="border-b border-foreground hover:bg-gray-900 "
            key={index}
          >
            <TableCell className="py-0">{order.time}</TableCell>
            <TableCell className="py-0">{order.instrument}</TableCell>
            <TableCell className="py-0">{order.type}</TableCell>
            <TableCell className="py-0">{order.limit_price}</TableCell>
            <TableCell className="py-1.5 text-destructive">
              {order.size}
            </TableCell>
            <TableCell className="py-0">{order.filled_size}</TableCell>
            <TableCell className="py-0">{order.time_in_price}</TableCell>
            <TableCell className="py-0">{order.action}</TableCell>
            <TableCell className="py-0">{order.order_id}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
