import { useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from "../../../components/ui/drawer";
import IconButton from "../../../components/IconButton";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
} from "../../../components/ui/navigation-menu";

import * as React from "react";

import { CryptoSelectBox } from "./CryptoSelectBox";
import { UserBalance } from "./UserBalance";
import { UserWallet } from "./UserWallet";

const navbarComponents: { title: string; href: string }[] = [
  {
    title: "Trade",
    href: "/trade",
  },
  {
    title: "Portfolio",
    href: "/portfolio",
  },
  {
    title: "Strategy Builder",
    href: "/strategy-builder",
  },
  {
    title: "Docs",
    href: "/docs",
  },
  {
    title: "Bridge",
    href: "/bridge",
  },
];

function DrawerMenu() {
  const navgiationItemStyle = `w-full group inline-flex h-10 rounded-md text-white text-opacity-90 bg-background
    px-4 py-2 text-xs font-medium transition-colors hover:text-white
    hover:bg-white hover:bg-opacity-10 hover:text-accent-foreground 
    focus:text-white focus:bg-white focus:bg-opacity-10 focus:outline-none 
    disabled:pointer-events-none disabled:opacity-50 
    data-[active]:bg-white/50 data-[state=open]:bg-white/50`;

  return (
    <Drawer direction="left">
      <DrawerTrigger asChild>
        <IconButton>
          <svg
            className="block h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </IconButton>
      </DrawerTrigger>
      <DrawerContent className=" rounded-l-none h-full w-2/3">
        <div className="flex flex-col max-w-full">
          <DrawerHeader className="self-end">
            <DrawerClose asChild>
              <IconButton>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DrawerClose>
          </DrawerHeader>
          <div className="p-4 pb-0">
            <div className="flex flex-col items-start justify-start space-x-2">
              <NavigationMenu className="flex  items-start flex-col ">
                <NavigationMenuItem
                  className={
                    "w-full group mt-4 inline-flex h-10 items-start justify-start rounded-md text-white text-opacity-90 bg-background px-4 py-2 text-xs font-medium transition-colors  data-[active]:bg-white/50 data-[state=open]:bg-white/50"
                  }
                >
                  <CryptoSelectBox customClasses="bg-background p-1.5" />
                </NavigationMenuItem>

                <NavigationMenuItem
                  className={`mt-4 items-start justify-start ${navgiationItemStyle}`}
                >
                  <UserBalance />
                </NavigationMenuItem>
                {navbarComponents.map((tab) => {
                  return (
                    <NavigationMenuItem
                      key={tab.title}
                      className="marker:text-transparent"
                    >
                      <NavigationMenuLink
                        href={tab.href}
                        className={`justify-center items-center ${navgiationItemStyle}`}
                      >
                        {tab.title}
                      </NavigationMenuLink>
                    </NavigationMenuItem>
                  );
                })}
              </NavigationMenu>
            </div>
            <div className="mt-3 h-[120px]"></div>
          </div>
          <DrawerFooter></DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export function Navbar() {
  const [selectedTabId, setSelectedTabId] = useState(navbarComponents[1].title);

  return (
    <header className="bg-transparent fixed top-0 w-full shadow-md z-20">
      <nav className="w-svw  ">
        <div className="bg-foreground mx-2 px-2 lg:px-8 rounded-br-md shadow-md rounded-bl-md">
          <div className="flex h-16 items-center justify-between">
            <div className="flex-1 inset-y-0 left-0 flex items-center lg:hidden">
              <DrawerMenu />
            </div>
            <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
              <a href="/" className="flex flex-shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src="/assets/images/synquote-logo.png"
                  alt="Synquote Logo"
                />
              </a>
              <div className="hidden lg:ml-6 lg:block">
                <div className="flex space-x-4">
                  {navbarComponents.map((tab) => {
                    let isSelected = selectedTabId === tab.title;

                    return (
                      <button
                        key={tab.title}
                        onClick={() => setSelectedTabId(tab.title)}
                        className={`${
                          isSelected ? "text-primary" : "text-muted"
                        } rounded-md px-3.5 py-2 text-[14px] font-medium text-nowrap`}
                        aria-current="page"
                      >
                        {tab.title}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex-1 justify-end inset-y-0 right-0 flex items-center content-center text-center lg:static lg:inset-auto lg:ml-6 lg:pr-0">
              <div className="hidden lg:flex">
                <CryptoSelectBox customClasses="bg-foreground" />
                <UserBalance />
              </div>

              <UserWallet />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
