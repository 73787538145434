import React from "react";

interface BackgroundSpinnerProps {
  sizeClass: string;
  positionClass?: string;
  zIndexClass?: string;
  additionalClasses?: string;
}

const BackgroundSpinner: React.FC<BackgroundSpinnerProps> = ({
  sizeClass,
  positionClass = "",
  zIndexClass = "z-[-10]",
  additionalClasses = "",
}) => {
  const baseClasses = `absolute overflow-hidden ${zIndexClass} ${positionClass} ${additionalClasses} ${sizeClass}`;

  return (
    <div className={baseClasses}>
      <img
        className="animate-[spin_20s_linear_infinite]"
        src="assets/images/circular-blink-bg.png"
        alt="Spinning background"
      />
    </div>
  );
};

export default BackgroundSpinner;
