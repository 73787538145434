import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
} from "../../../components/ui/navigation-menu";

const footerComponents: { title: string; href: string }[] = [
  {
    title: "Docs",
    href: "/docs",
  },
  {
    title: "Support",
    href: "/support",
  },
  {
    title: "Terms of Service",
    href: "/terms-of-service",
  },
  {
    title: "Privacy",
    href: "/privacy",
  },
];

export function Footer() {
  return (
    <>
      <footer className="max-w-5xl mx-auto px-4 mt-3 mb-9 z-10 relative">
        <div className="flex flex-col  text-xs items-center justify-between md:flex-row">
          <div className="flex cursor-default items-center uppercase text-primary font-gridnik tracking-widest">
            <div className="animate-ping h-1.5 w-1.5 mr-2 bg-primary rounded-full"></div>
            operational
          </div>
          <div>
            <NavigationMenu className="flex-wrap mt-8 md:flex-wrap md:mt-0 ">
              {footerComponents.map((tab) => (
                <NavigationMenuItem
                  className="marker:text-transparent"
                  key={tab.title}
                >
                  <NavigationMenuLink
                    href={tab.href}
                    className={`group inline-flex h-10 w-max items-center justify-center rounded-md 
                      text-white text-opacity-90 bg-background px-4 py-2 text-xs font-medium 
                      transition-colors hover:text-white hover:bg-white hover:bg-opacity-10 
                      hover:text-accent-foreground focus:text-white focus:bg-white focus:bg-opacity-10  
                      focus:outline-none disabled:pointer-events-none disabled:opacity-50 
                      data-[active]:bg-white/50 data-[state=open]:bg-white/50`}
                  >
                    {tab.title}
                  </NavigationMenuLink>
                </NavigationMenuItem>
              ))}
            </NavigationMenu>
          </div>
        </div>
      </footer>
    </>
  );
}
